
.logout_menu {
  display: flex;
  position: absolute;
  top: 0px;
  left:0px;
  align-items: self-end ;
  flex-direction: row-reverse;
  flex:1;


  width: 100%;
}


.App {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 300px;
  height: 100vh;




}

h1 {
  font-size: 28pt;
  color: black;
  margin:0;
  line-height: normal;
}
h2 {
  font-size: 14pt;
  font-weight: normal;
  color: black;
  margin:0;
}

.title {
  margin-bottom: 1rem;
}

.login_submit{
  display: flex;
  width: 100%;
  flex-direction: row-reverse;


}

.login_submit button{
font-family: cursive;


}
.yellowbox{
  position: absolute;
  z-index: 900;
  width:100%;
  height: 200px;
  max-width: 500px;
  top: 100px;
  margin: 0 auto;
  padding: 10px;
  color: black;
  background-color: #f6d45c;
  border-radius: 5px;
  box-shadow: 2px 2px 8px grey;
}

button.yellowbox__button:focus {outline:0;}
button.yellowbox__button {

  -webkit-appearance: none; -moz-appearance: none;
  display: block;
  width: 100%;
  height: 40px;
  border: 2px solid white;
  border-radius: 10px;
  margin: 20px;
  min-width: 100px;


  font-family: sans-serif;
  color: white;
  font-weight: bold;
  background-color: transparent;
  box-shadow: 2px 2px 8px grey;
  font-size: 14pt;

}

button.yellowbox__button:hover{
  box-shadow: 0px 0px 8px grey;
}

.yellowbox>div.yellowbox__container{
  display:flex;
  flex: 1;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.yellowbox div.bottom{
  justify-content: flex-end;
  display: flex;
}

.yellowbox .yellowbox__sign{
  font-size: 50px;
  font-weight: bold;
}


.warning{
  position: absolute;
  z-index: 1000;
  width:100%;
  max-width: 500px;
  top: 100px;
  margin: 0 auto;
  padding: 10px;
  background-color: #f6717f;
  border-radius: 5px;
  box-shadow: 2px 2px 8px grey;
}

.warning>div{
  display:flex;
  align-items: center;
  justify-content: center;

  flex-direction: row;
}


.warning>div{


  font-weight: bold;
}


.warning .warning__sign{
  margin-left: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
font-size: 50px;
  font-weight: bold;

}



.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  box-shadow: none;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.logout  {


  padding: 10px;
  text-decoration: none ;
  font-size: 20pt;
  color: white;
  text-transform: uppercase;


  text-shadow: 2px 2px 8px grey;

}

.wrapper {

  text-align: center;
  width: 300px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}



/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-top: 5px;
  margin-bottom: 20px;
  cursor: pointer;
  color: black;
  font-size: 12pt;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {

  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: solid 2px gray;
  border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #444;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.bottom {
  height: 40px;
  position: fixed;
  bottom:0%;
  width:100%;
  /*z-index: -100;*/
  opacity: 1;


}
.bottom__container {

}