

div {
  font-size: 12pt;
}

/*
input[type=text], input[type=url], input[type=email], input[type=password], input[type=tel] {

  display: block;
  box-sizing: border-box;
  margin: 8px;

  padding: 8px;
  border-radius: 10px;
  width: 100%;
  height: 50px;
  line-height: 40px; font-size: 17px;
  border: 1px solid gray;
  margin-bottom: 10px;

}
*/


.select-css {
  display: block;
  font-size: 14pt;
  font-family: sans-serif;

  color: #757575;
  line-height: 1.3;
  padding: .6em 1.4em .5em .8em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 5px 0px;

  border: 2px solid gray;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  border-radius: 10px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%2300000%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: .65em auto, 100%;
}
.select-css::-ms-expand {
  display: none;
}
.select-css:hover {
  border-color: #888;
}
.select-css:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}
.select-css option {
  font-weight:normal;
}

a {
  font-size: 12pt;
}
a:hover {
  text-decoration: underline;
}

input {
  font-size: 14pt;
  font-family: sans-serif;
}

button.primary:focus {outline:0;}
input[type=submit], button.primary {

  -webkit-appearance: none; -moz-appearance: none;
  display: block;

  margin: 5px 0;

  width: 100%;
  height: 50px;
  border: 2px solid white;
  border-radius: 10px;

  font-family: sans-serif;
  color: white;
  font-weight: bold;
  background-color: #444;
  box-shadow: 2px 2px 8px grey;
  font-size: 14pt;

}

button.secondary:focus {outline:0;}
input[type=submit], button.secondary {

  -webkit-appearance: none; -moz-appearance: none;
  display: block;

  margin: 5px 0;

  width: 100%;
  height: 50px;
  border: 2px solid white;
  border-radius: 10px;

  font-family: sans-serif;
  color: white;
  font-weight: bold;
  background-color: transparent;

  font-size: 14pt;

}
button:hover{
box-shadow: 0px 0px 8px grey;
}



button.primary {
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

button.secondary {
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


input.passcode, input.name {
  width: 100%;
  box-sizing: border-box;
  background: white;
  margin: 5px 0;
  padding: 8px;
  border-radius: 10px;
  height: 50px;
  font-size: 17px;
  border: 2px solid gray;

}

button.primary:active {background-color: #222}

div.phoneInput {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  background: white;
  margin: 5px 0px;
  padding: 0px;
  border-radius: 10px;
  height: 50px;
  font-size: 17px;
  border: 2px solid gray;
  margin-bottom: 10px;

}
div.phoneInput>input {

  box-sizing: border-box;

  border: none;
  border-left: 1px solid #888;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  background: transparent;
  padding: 0;
  padding-left: 10px;
  width: 100%;

  margin:0;
}

div.phoneInput button {

  display: flex;
  align-items:center;


  height: unset;
}


p {
  font-size: 12pt;
  color: black;
}

.usageContainer {
  position: absolute;
  top: 0;
  border-color: #888888;
  border-radius: 10px;

  text-align: left;
  width: 100%;
  max-width: 500px;
  margin: 40px auto;

  padding: 30px;
  background-color: white;
  color: black;
}
.usage {
  margin: 10px;
}

body {
  margin: 0;
  padding: 0;
  background-color: #b9bbbc;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12pt;
}
